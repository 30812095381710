import { Controller } from 'stimulus'
import application from 'controllers'

document.addEventListener("turbo:submit-end", event => {
  if (event.detail.formSubmission.mustRedirect && event.detail.formSubmission.result.success) {
    window.Turbo.visit(event.detail.fetchResponse.response.url)
  }
})

application.register(
  'devices-edit',
  class extends Controller {
    static values = {
      deviceid: String,
      servicetag: String
    }

    initialize() {
      this.url = `/devices/${this.servicetagValue}/broadcast_realtime_attributes`
      this.commands = []
      if (document.getElementById(`device_${this.deviceidValue}_wifi_status`)) {
        this.commands.push("wifi_status")
      }
      if (document.getElementById(`device_${this.deviceidValue}_dhcp_wlan_ip_address`)) {
        this.commands.push("dhcp_wlan_ip_address")
      }
      if (document.getElementById(`device_${this.deviceidValue}_dhcp_wan_ip_address`)) {
        this.commands.push("dhcp_wan_ip_address")
      }
    }

    connect() {
      if (this.commands.length > 0) {
        let params = new URLSearchParams({ commands: this.commands }).toString()
        fetch(`${this.url}?${params}`)
      }
    }

    toggleMenuButtons(event) {
      event.preventDefault()
      event.target.classList.toggle("bg-gray-200")
      document.getElementById("menu-buttons").classList.toggle("important-hidden")
    }
  }
)
