import { Controller } from 'stimulus'
import application from 'controllers'

application.register(
  "internal-user-menu",
  class extends Controller {
    static targets = ["menu"]
    static classes = [ "toggle" ]

    toggle() {
      this.menuTarget.classList.toggle(this.toggleClass)
    }
  },
)
