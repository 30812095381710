import {
  Controller
} from 'stimulus'
import application from 'controllers'

application.register(
  'client--ui--footer',
  class extends Controller {
    static targets = [
      "vpnUptime",
      "workstationSelect"
    ]

    initialize() {
      this.vpnUptimeInterval = null
    }

    connect() {
      this.vpnConnectionInfo()
      this.handleVpnConnectionInfoUpdates()
    }

    // This function calls the Electron API to connect to the VPN
    async connectToVpn(event) {
      window.electronAPI.vpnIsRunning().then(async (isRunning) => {
        if (!isRunning) {
          const connectionType = event.target.dataset.type

          await this.downloadOvpnCert(connectionType)

          const selectedOption = this.workstationSelectTarget.options[this.workstationSelectTarget.selectedIndex]
          this.workstationUsername = selectedOption.getAttribute("data-username")

          window.electronAPI.connectToVpn(this.workstationUsername, this.base64Cert, connectionType)
        }
      })
    }

    // This function calls the Electron API to disconnect from the VPN
    async disconnectFromVpn(event) {
      window.electronAPI.vpnIsRunning().then(async (isRunning) => {
        if (isRunning) {
          window.electronAPI.disconnectFromVpn()
        }
      })
    }

    // This function downloads the OVPN certificate from the server and passes it to the Electron API
    async downloadOvpnCert(type) {
      const fileName = type === "l2" ? "l2_ovpn.dev" : "ovpn.dev"

      const response = await fetch(
        `/client/files/${fileName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": document.head.querySelector('meta[name="csrf-token"]').content,
          }
        }
      )
      await response.json().then((data) => {
        this.base64Cert = data.ovpn_cert
      })
    }

    // This function calls the Electron API to get the VPN connection info
    vpnConnectionInfo() {
      window.electronAPI.vpnConnectionInfo().then((info) => {
        if (info.status !== 'connected') { clearInterval(this.vpnUptimeInterval) }

        this.currentVpnConnectionInfo = info
        this.updateDomWithVpnConnectionInfo(info).then(() => { return })
      })
    }

    // This function listens for updates to the VPN connection info
    handleVpnConnectionInfoUpdates() {
      window.electronAPI.onVpnInfoUpdate((info) => {
        if (info.status !== 'connected') { clearInterval(this.vpnUptimeInterval) }
        if (this.currentVpnConnectionInfo.status === info.status) { return }

        this.currentVpnConnectionInfo = info
        this.updateDomWithVpnConnectionInfo(info)
      })
    }

    updateDomWithVpnConnectionInfo(info) {
      this.updateVpnUptime(info.connectedAt)

      return fetch(`/client/realms/update_dom_with_vpn_status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "text/vnd.turbo-stream.html",
          "X-CSRF-Token": document.head.querySelector('meta[name="csrf-token"]').content,
        },
        body: JSON.stringify(this.clientVpnBody(info))
      }).then(response => response.text())
        .then(html => Turbo.renderStreamMessage(html))
        .then(() => {
          if (info.status !== this.currentVpnConnectionInfo.status) {
            this.updateDomWithVpnConnectionInfo(this.currentVpnConnectionInfo)
          }
        })
    }

    clientVpnBody(info) {
      return {
        client_vpn: {
          connected_at: info.connectedAt,
          origin_url: location.href,
          status: info.status,
          type: info.type,
          workstation_username: info.workstationUsername
        }
      }
    }

    updateVpnUptime(uptime) {
      if (!uptime) { return }

      this.vpnUptimeInterval = setInterval(() => {
        const now = new Date()
        const diffInMs = now.getTime() - new Date(uptime).getTime()
        const diffInSeconds = Math.floor(diffInMs / 1000)
        const minutes = Math.floor(diffInSeconds / 60)
        const seconds = diffInSeconds % 60
        const formattedMinutes = String(minutes).padStart(2, '0')
        const formattedSeconds = String(seconds).padStart(2, '0')
        const time = `${formattedMinutes}:${formattedSeconds}`

        this.vpnUptimeTarget.innerHTML = time
      }, 1000)
    }

    openDeviceSetupPage(event) {
      window.electronAPI.openPortal(event.target.dataset.path)
    }
  }
)
