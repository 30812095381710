import { Controller } from 'stimulus'
import application from 'controllers'

application.register(
  'internal-devices-show',
  class extends Controller {
    static values = {
      deviceid: String,
      servicetag: String
    }

    initialize() {
      this.url = `/internal/devices/${this.servicetagValue}/broadcast_realtime_attributes`
      this.commands = []
      // device_85_internal_dhcp_wlan_ip_address
      if (document.getElementById(`device_${this.deviceidValue}_internal_wifi_status`)) {
        this.commands.push("wifi_status")
      }
      if (document.getElementById(`device_${this.deviceidValue}_internal_dhcp_wlan_ip_address`)) {
        this.commands.push("dhcp_wlan_ip_address")
      }
      if (document.getElementById(`device_${this.deviceidValue}_internal_dhcp_wan_ip_address`)) {
        this.commands.push("dhcp_wan_ip_address")
      }
    }

    connect() {
      if (this.commands.length > 0) {
        let params = new URLSearchParams({commands: this.commands}).toString()
        fetch(`${this.url}?${params}`)
      }
    }
  }
)
