import { Controller } from "stimulus";
import application from "controllers";

application.register(
  "ui--search",
  class extends Controller {
    static targets = ["searchInput"];

    connect() {
      this.searchInputTarget.addEventListener("input", (event) => {
        if (event.currentTarget.value.length === 0) {
          window.location = window.location.pathname;
        }
      });
    }
  },
);
