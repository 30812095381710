import { Controller } from 'stimulus'
import application from 'controllers'

application.register(
  'lan-r1--internal-module',
  class extends Controller {
    static targets = ["lanDHCPParams"]
    static values = {
      url: String
    }

    toggle() {
      this.lanDHCPParamsTarget.classList.toggle("hidden", this.lanDHCPParamsTarget.checked)
    }
  },
)
