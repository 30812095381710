import { Controller } from 'stimulus'
import application from 'controllers'

application.register('ui--ip-address', class extends Controller {
  static values = { ipAddress: String }
  static targets = ["ip", "ipStart"]

  connect() {
    this.element.addEventListener('submit', this.updateIP.bind(this));
  }

  updateIP(event) {
    const input = event.target
    const ipStart = this.ipStartTarget.textContent.trim()
    const ipEnd = input.value
    const fullIP = `${ipStart}${ipEnd}`

    this.ipTarget.value = fullIP
  }
})
