import { Controller } from 'stimulus'
import application from 'controllers'

application.register(
  'ui--toggable-key',
  class extends Controller {
    static targets = ["showed", "hidden", "clipboard"]

    initialize() {
      this.visible = false
    }

    connect() {
      this.showedTarget.classList.add("hidden")
    }

    toggle() {
      this.visible = !this.visible
      this.showedTarget.classList.toggle("hidden", !this.visible)
      this.hiddenTarget.classList.toggle("hidden", this.visible)
    }

    copy(evt) {
      this.clipboardTarget.querySelector('.fa-check').classList.remove("hidden")
      this.clipboardTarget.querySelector('.fa-clipboard').classList.add("hidden")
      let tempInput = document.createElement("input")
      tempInput.value = this.clipboardTarget.dataset.value
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand("copy")
      document.body.removeChild(tempInput)
      setTimeout(() => {
        this.clipboardTarget.querySelector('.fa-check').classList.add("hidden")
        this.clipboardTarget.querySelector('.fa-clipboard').classList.remove("hidden")
      }, 2000)
    }
  }
)
