import { Controller } from 'stimulus'
import application from 'controllers'

application.register(
  'profile',
  class extends Controller {
    static targets = [
      "updateButton",
      "editButton",
      "mobileEditButton",
      "cancelButton",
      "mobileCancelButton",
      "profileForm",
      "profileShow",
      "clipboard"
    ]

    enableEdit() {
      this.updateButtonTarget.classList.remove('hidden')
      this.profileShowTarget.classList.add('hidden')
      this.editButtonTarget.classList.add('hidden')
      this.mobileEditButtonTarget.classList.add('hidden')
      this.cancelButtonTarget.classList.remove('hidden')
      this.mobileCancelButtonTarget.classList.remove('hidden')
      this.profileFormTarget.classList.remove('hidden')
    }

    disableEdit() {
      window.location.reload();
    }

    copy(evt) {
      this.clipboardTarget.querySelector('.fa-check').classList.remove("hidden")
      this.clipboardTarget.querySelector('.fa-clipboard').classList.add("hidden")
      let tempInput = document.createElement("input")
      tempInput.value = this.clipboardTarget.dataset.value
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand("copy")
      document.body.removeChild(tempInput)
      setTimeout(() => {
        this.clipboardTarget.querySelector('.fa-check').classList.add("hidden")
        this.clipboardTarget.querySelector('.fa-clipboard').classList.remove("hidden")
      }, 2000)
    }
  },
)
