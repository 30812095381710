import { Controller } from 'stimulus'
import application from 'controllers'

application.register(
  'internal--operators',
  class extends Controller {
    static targets = ["modalOTC", "clipboard"]

    connect() {
      document.addEventListener("keydown", this.closeHandler.bind(this))
    }

    disconnect() {
      document.removeEventListener("keydown", this.closeHandler.bind(this))
    }

    closeHandler(event) {
      if (event.keyCode == 27) {
        this.closeModal();
      }
    }

    openModal() {
      this.modalOTCTarget.classList.remove('hidden');
    }

    closeModal() {
      this.modalOTCTarget.classList.add('hidden');
    }
    
    copy(evt) {
      this.clipboardTarget.querySelector('.fa-check').classList.remove("hidden")
      this.clipboardTarget.querySelector('.fa-clipboard').classList.add("hidden")
      let tempInput = document.createElement("input")
      tempInput.value = this.clipboardTarget.dataset.value
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand("copy")
      document.body.removeChild(tempInput)
      setTimeout(() => {
        this.clipboardTarget.querySelector('.fa-check').classList.add("hidden")
        this.clipboardTarget.querySelector('.fa-clipboard').classList.remove("hidden")
      }, 2000)
    }
  },
)
