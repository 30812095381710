import { Controller } from 'stimulus'
import application from 'controllers'

const flagModes = {station: 'staParamsTarget', ap: 'wanParamsTarget'}
application.register(
  'wifi--internal-module',
  class extends Controller {
    static targets = [
      "wifiToggleParams",
      "wifiParams",
      "toggleWifiMode",
      "staParams",
      "wanParams",
      "staParamsEnabled",
      "toggleStaParams",
      "wanParamsEnabled",
      "toggleWanParams",
      "dhcpIpParams"
    ]

    static values = {
      url: String,
      wifiStatusUrl: String
    }

    reload(event) {
      event.currentTarget.classList.add('loading')
    }

    connect() {
      this.enabled = this.wifiToggleParamsTarget.checked
      this.toggleElement(this.wifiParamsTarget, this.enabled)
      this.changeMode()
      this.toggleStaParams()
      this.toggleWanParams()
      fetch(this.urlValue)
      fetch(this.wifiStatusUrlValue)
    }

    changeMode() {
      for (var prop in flagModes) {
        let visible = prop == this.toggleWifiModeTarget.value
        this.toggleElement(this[flagModes[prop]], visible)
      }
    }

    toggleParams() {
      this.enabled = !this.enabled
      this.toggleElement(this.wifiParamsTarget, this.enabled)
    }

    toggleElement(element, visible) {
      element.classList.toggle("hidden", !visible)
    }

    // NOTE: STA params
    toggleStaParams() {
      this.toggleElement(this.toggleStaParamsTarget, !this.staParamsEnabledTarget.checked)
      this.dhcpIpParamsTarget.classList.toggle("hidden", !this.staParamsEnabledTarget.checked)
    }

    // NOTE: WAN params
    toggleWanParams() {
      this.toggleElement(this.toggleWanParamsTarget, this.wanParamsEnabledTarget.checked)
    }
  },
)
