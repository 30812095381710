import {
  Controller
} from 'stimulus'
import application from 'controllers'

application.register(
  'client--ui--spinner',
  class extends Controller {
    static targets = ["spinner"]

    connect() {
      setInterval(() => {
        this.spinnerTarget.style.transform += "rotate(45deg)"
      }, 100)
      this.timeout = setTimeout(() => {
        location.reload()
      }, 3000)
    }

    disconnect() {
      clearTimeout(this.timeout)
    }
  }
)
