import {
  Controller
} from 'stimulus'
import application from 'controllers'

application.register(
  'client--devices--show',
  class extends Controller {
    static targets = ["portalLink"]

    openDeviceOnPortal(event) {
      window.electronAPI.openPortal(`devices/${this.portalLinkTarget.dataset.serviceTag}`)
    }
  }
)
