import {
  Controller
} from 'stimulus'
import application from 'controllers'

application.register(
  'client--ui--header',
  class extends Controller {
    static targets = ["menu"]

    toggleMenu() {
      this.menuTarget.classList.toggle('hidden')
    }

    openPortalOnDefaultBrowser() {
      window.electronAPI.openPortal()

      this.toggleMenu()
    }
  }
)
