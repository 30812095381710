import { Controller } from 'stimulus'
import application from 'controllers'

application.register(
  'burger-menu',
  class extends Controller {
    static targets = ["menu", "button", "wrapper"]

    toggle() {
      this.menuTarget.classList.toggle("hidden");
      this.buttonTarget.classList.toggle("active");
      this.wrapperTarget.classList.toggle("fixed");
      document.getElementsByTagName("main")[0].classList.toggle("fixed");
    }
  }
)
