import { Controller } from 'stimulus'
import application from 'controllers'

application.register(
  'audit--connections',
  class extends Controller {
    static targets = ['filter']

    toggleFilters() {
      this.filterTarget.classList.toggle('hidden')
    }

    closeFilters() {
      this.filterTarget.classList.add('hidden')
    }

    connect() {
      document.addEventListener("keydown", this.closeHandler.bind(this))
    }

    disconnect() {
      document.removeEventListener("keydown", this.closeHandler.bind(this))
    }

    closeHandler(event) {
      if (event.keyCode == 27) {
        this.closeFilters();
      }
    }
  }
)
