import { Controller } from 'stimulus'
import application from 'controllers'

application.register(
  'users',
  class extends Controller {
    static targets = []
    toggleSortMenu() {
      event.preventDefault()
      document.getElementById("sort-menu").classList.toggle("hidden")
    }
  }
)
