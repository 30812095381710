import consumer from "./consumer"
import Push from "push.js"

consumer.subscriptions.create("DesktopChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    var event = data.operations[0];

    Push.create(event.code, {
      body: event.title,
      timeout: 6000,
      link: event.url,
      onClick: function () {
        window.focus();
        this.close();
      }
    });
  }
});