import { Controller } from 'stimulus'
import application from 'controllers'

application.register(
  'user-menu',
  class extends Controller {
    static targets = ["submenu", "menu"]
    static classes = [ "toggle" ]

    toggle() {
      this.submenuTarget.classList.toggle(this.toggleClass)
      this.menuTarget.classList.toggle("menu-open")
    }
  },
)
