import {
  Controller
} from 'stimulus'
import application from 'controllers'

application.register(
  'client--realms--index',
  class extends Controller {
    static targets = ["realms", "search", "sort"]

    connect() {
      this.searchTarget.focus()

      this.searchTarget.addEventListener('input', this.search.bind(this))

      this.sortTarget.addEventListener('click', this.sort.bind(this))
    }

    search() {
      const search = this.searchTarget.value.toLowerCase()

      this.element.querySelectorAll('.realm').forEach(realm => {
        const name = realm.dataset.name.toLowerCase()

        if (name.includes(search)) {
          realm.classList.remove('hidden')
        } else {
          realm.classList.add('hidden')
        }
      })
    }

    sort() {
      const sortedRealms = Array.from(document.querySelectorAll('.realm')).reverse()

      this.realmsTarget.innerHTML = null

      sortedRealms.forEach(realm => {
        this.realmsTarget.appendChild(realm)
      })
    }
  },
)
