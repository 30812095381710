import { Controller } from 'stimulus'
import application from 'controllers'

application.register(
  'edit-wifi-r1-module',
  class extends Controller {
    static targets = [
      "wifiToggleEnabled",
      "wifiParams",
      "toggleWifiMode",
      "clientParams",
      "dhcpClientParams",
      "dhcpClientToggle",
      "toggleWifiCountry",
      "toggleWifiFrequency"
    ]

    static values = {
      url: String,
      wifiStatusUrl: String
    }

    connect() {
      // is enabled?
      this.enabled = this.wifiToggleEnabledTarget.checked
      
      // toggle all params
      this.wifiParamsTarget.classList.toggle("hidden", !this.enabled)

      // preset values by selected mode
      if (this.enabled) {
        this.changeMode()
        this.dhcpClientParamsToggle()
      }
    }

    isStation() {
      return this.toggleWifiModeTarget.value == "station"
    }

    isAccessPoint() {
      return !this.isStation()
    }
    
    changeMode() {
      this.clientParamsTarget.classList.toggle("hidden", this.isAccessPoint())
      this.dhcpClientParamsToggle()
      this.setWifiCountry()
      this.setWifiFrequency()
    }

    // hide/show all wifi params
    toggleParams(event) {
      this.enabled = event.target.checked
      this.wifiParamsTarget.classList.toggle("hidden", !this.enabled)
      this.changeMode()
      this.dhcpClientParamsToggle()
    }

    // NOTE: STA params
    dhcpClientParamsToggle() {
      this.dhcpClientParamsTarget.classList.toggle("hidden", this.dhcpClientToggleTarget.checked)
    }

    // - hide option auto (first option) in select
    // - if access point was selected then select option IT
    setWifiCountry() {
      if (this.isAccessPoint()) {
        this.toggleWifiCountryTarget.querySelector('option').classList.add('hidden')
        if (this.toggleWifiCountryTarget.value == '00')
          this.toggleWifiCountryTarget.value = 'IT'
      } else {
        this.toggleWifiCountryTarget.querySelector('option').classList.remove('hidden')
        this.toggleWifiCountryTarget.value = '00'
      }
    }

    setWifiFrequency() {
      if (this.isAccessPoint()) {
        this.toggleWifiFrequencyTarget.querySelector('option').classList.add('hidden')
        if (this.toggleWifiFrequencyTarget.value == '0')
          this.toggleWifiFrequencyTarget.value = '1'
      } else {
        this.toggleWifiFrequencyTarget.querySelector('option').classList.remove('hidden')
        this.toggleWifiFrequencyTarget.value = '0'
      }
    }
  },
)
