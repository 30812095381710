// import '@stimulus/polyfills'
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

const application = Application.start()

// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.
const context = require.context('controllers', true, /[_-]controller\.js$/)
application.load(definitionsFromContext(context))

export default application
