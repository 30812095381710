import { Controller } from 'stimulus'
import application from 'controllers'

application.register(
  'edit-proxy-r1-module',
  class extends Controller {
    static targets = ["proxyParams"]

    toggle(event) {
      this.proxyParamsTarget.classList.toggle("hidden", !event.target.checked)
    }
  },
)
