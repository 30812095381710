import {
  Controller
} from 'stimulus'
import application from 'controllers'

application.register(
  'connection--index',
  class extends Controller {
    static targets = ["checkbox", "edit", "cancel", "update", "editMobile", "cancelMobile"]

    edit(event) {
      this.editTarget.classList.add("hidden")
      this.editMobileTarget.classList.add("hidden")
      this.cancelTarget.classList.remove("hidden")
      this.updateTarget.classList.remove("hidden")
      this.cancelMobileTarget.classList.remove("hidden")

      document.querySelectorAll('input.connection-checkbox').forEach(checkbox => {
        checkbox.disabled = false;
      });
    }
  },
)
