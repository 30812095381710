import { Controller } from 'stimulus'
import application from 'controllers'
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import Cookies from 'js-cookie'

application.register(
  'devices',
  class extends Controller {
    static targets = [
      "field",
      "map",
      "mapContainer",
      "latitude",
      "longitude",
      "deviceRow",
      "globeButton",
      "zoomButton",
      "devicesTableBody",
      "devicesTable"
    ]

    initialize() {
      this.markers = [];
      this.center = {
        lat: 51.508530,
        lng: -0.076132,
        zoom: 2
      }
      this.buildMap();
    }

    connect() {
      this.toggleTable();
      this.toggleGlobe();

      if (typeof (google) != "undefined") {
        this.initMap()
      }
    }

    zoomOutMap(event) {
      this.markers.forEach(completeMarker => completeMarker.infowindow.close());
      let position = new google.maps.LatLng(this.center.lat, this.center.lng);

      this.deviceRowTargets.forEach(target => {
        target.classList.remove('selected')
      })

      this.map.setCenter(position);
      this.map.setZoom(this.center.zoom);
    }

    toggleMap() {
      this.toggleGlobe();
      this.toggleTable()
    }

    toggleTable() {
      this.zoomButtonTarget.classList.toggle("pressed");
      this.mapTarget.closest(".bottom-half").classList.toggle("hidden", this.visible);

      this.devicesTableTarget.classList.toggle("collapsed", this.visible);
      this.devicesTableBodyTarget.classList.toggle("collapsed");
    }

    toggleGlobe() {
      this.globeButtonTarget.classList.toggle("pressed");
    }

    setPosition(event) {
      event.preventDefault()
      event.stopImmediatePropagation()
      let lat = this.center.lat;
      let lng = this.center.lng;
      let zoom = this.center.zoom;

      if (event.currentTarget.dataset.latitude && event.currentTarget.dataset.longitude) {
        lat = event.currentTarget.dataset.latitude;
        lng = event.currentTarget.dataset.longitude;
        zoom = 14;
      } else {
        this.markers.forEach(completeMarker => completeMarker.infowindow.close());
      }

      let position = new google.maps.LatLng(lat, lng);

      this.deviceRowTargets.forEach(target => {
        target.classList.remove('selected')
      })

      event.currentTarget.closest("tr").classList.add('selected')

      this.map.setCenter(position);
      this.map.setZoom(zoom);

      for (var completeMarker of this.markers) {
        let lat = completeMarker.marker.position.lat()
        let lng = completeMarker.marker.position.lng()
        if (lat == position.lat() && lng == position.lng()) {
          completeMarker.infowindow.setContent(completeMarker.tooltip)
          completeMarker.infowindow.open(this.map, completeMarker.marker)
        }
      }
    }

    addListenerDetails(infowindow, marker, map, content) {
      marker.addListener('click', function () {
        map.panTo(marker.position);
        map.setZoom(14);
        infowindow.setContent(content);
        infowindow.open(map, this);
      });
    }

    clusterizeMarkers(map, markers) {
      new MarkerClusterer({
        map,
        markers
      });
    }

    buildMap() {
      this.mapTarget.classList.add("bottom-devices-map")
      this.mapContainerTarget.classList.add("bottom-half")
      this.mapContainerTarget.classList.remove("hidden");
    }

    drawMap(data) {
      var infowindow = new google.maps.InfoWindow({
        content: ""
      });

      var markers = [];

      for (var i = 0; i < data.length; i++) {
        var tooltip = `
        <a href="/devices/${data[i].service_tag}" class="tooltip-map">
        <div class="font-bold flex items-center">
        <span class="mini-led">
        <span class="${data[i].connection_state}"></span>
        </span>
          ${data[i].name || "&mdash;"}
        </div>
        <div class="text-gray-600">
        ${data[i].service_tag}
        </div>
        <div class="mt-1">
        ${data[i].location_address}
        </div>
        </a>
        `;

        var latitude = data[i].location_latitude;
        var longitude = data[i].location_longitude;

        var position = new google.maps.LatLng(latitude, longitude);

        this.marker = new google.maps.Marker({
          position: position,
          map: this.map,
          icon: '/logos/small.png'
        });

        this.addListenerDetails(infowindow, this.marker, this.map, tooltip)

        this.markers.push({ marker: this.marker, infowindow: infowindow, tooltip: tooltip });
        window.markers = this.markers;
        markers.push(this.marker)
      }

      this.clusterizeMarkers(this.map, markers);
    }

    initMap() {
      this.map = new google.maps.Map(this.mapTarget, {
        center: new google.maps.LatLng(this.center.lat, this.center.lng),
        zoom: this.center.zoom,
        minZoom: 1
      });

      let tag = ''
      let query = ''
      let items = null
      let url = ''
      const urlParams = new URLSearchParams(location.search);

      if (urlParams.get('q')) {
        query = urlParams.get('q')
      }

      if (urlParams.get('tag')) {
        tag = urlParams.get('tag')
      }

      if (urlParams.get('items')) {
        items = urlParams.get('items')
      }

      if (items) {
        url = '/devices.json?q=' + query + '&tag=' + tag + '&items=' + items
      } else {
        url = '/devices.json?q=' + query + '&tag=' + tag
      }

      fetch(url)
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          if (data.length > 0) {
            this.drawMap(data)
          }
        })
    }

    toggleSortMenu(event) {
      event.preventDefault()
      document.getElementById("sort-menu").classList.toggle("hidden")
    }
  }
)
