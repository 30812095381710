import { Controller } from 'stimulus'
import application from 'controllers'

application.register(
  'internal--modal--operators',
  class extends Controller {
    static targets = ["modalOTC"]

    connect() {
      document.addEventListener("keydown", this.closeHandler.bind(this))
    }

    disconnect() {
      document.removeEventListener("keydown", this.closeHandler.bind(this))
    }

    closeHandler(event) {
      if (event.keyCode == 27) {
        this.closeModal();
      }
    }

    openModal() {
      this.modalOTCTarget.classList.remove('hidden');
    }

    closeModal() {
      this.modalOTCTarget.classList.add('hidden');
    }
  },
)
