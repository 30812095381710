import { Controller } from 'stimulus'
import application from 'controllers'

application.register(
  'mobile-internal-r1-module',
  class extends Controller {
    static targets = ["mobileParams", "mobileEnabled"]

    connect() {
      this.enabled = this.mobileEnabledTarget.checked
      this.toggleElement(this.mobileParamsTarget, this.enabled)
    }

    toggleParams() {
      this.enabled = !this.enabled
      this.toggleElement(this.mobileParamsTarget, this.enabled)
    }

    toggleElement(element, visible) {
      element.classList.toggle("hidden", !visible)
    }
  },
)
