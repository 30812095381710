import {
  Controller
} from 'stimulus'
import application from 'controllers'

application.register(
  'operator--accesses--index',
  class extends Controller {
    static targets = ["checkbox", "edit", "mobileEdit", "mobileCancel", "cancel", "update", "checkboxAll"]

    toggleAll(event) {
      document.querySelectorAll('input.device-checkbox').forEach(checkbox => {
        checkbox.checked = event.target.checked;
      });
    }

    edit(event) {
      this.editTarget.classList.add("hidden")
      this.mobileEditTarget.classList.add("hidden")
      this.checkboxAllTarget.classList.remove("hidden")
      this.cancelTarget.classList.remove("hidden")
      this.mobileCancelTarget.classList.remove("hidden")
      this.updateTarget.classList.remove("hidden")

      document.querySelectorAll('input.device-checkbox').forEach(checkbox => {
        checkbox.disabled = false;
      });
    }
  },
)
