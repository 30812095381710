import { Controller } from 'stimulus'
import application from 'controllers'
import Tagify from '@yaireo/tagify'

application.register(
  'tags--module',
  class extends Controller {
    static targets = ["tagList"]
    static values = {
      url: String
    }

    connect() {
      fetch(this.urlValue)
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          new Tagify(this.tagListTarget, {
            maxTags: 4,
            whitelist: data,
            originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(',')
          });
        })
    }
  }
)