import { Controller } from 'stimulus'
import application from 'controllers'

application.register(
  'ui--secret',
  class extends Controller {
    static targets = ["show", "hidden", "hiddenField", "showField", "realSecret"]

    initialize() {
      this.visible = false
      this.wink()
    }

    connect() {
      this.showTarget.classList.add("hidden")
    }

    changed(evt) {
      this.showFieldTarget.value = evt.target.value
      this.hiddenFieldTarget.value = evt.target.value
      this.realSecretTarget.value = evt.target.value
    }

    wink() {
      this.visible = !this.visible

      this.showTarget.classList.toggle("hidden", this.visible)
      this.hiddenTarget.classList.toggle("hidden", !this.visible)
    }
  }
)
