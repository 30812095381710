import { Controller } from 'stimulus'
import application from 'controllers'

application.register(
  'internal-ipf-le-module',
  class extends Controller {
    static targets = ["ipfLeParams", "ipfLeEnabled"]

    connect() {
      this.enabled = this.ipfLeEnabledTarget.checked
      this.toggleElement(this.ipfLeParamsTarget, this.enabled)
    }

    toggleParams() {
      this.enabled = !this.enabled
      this.toggleElement(this.ipfLeParamsTarget, this.enabled)
    }

    toggleElement(element, visible) {
      element.classList.toggle("hidden", !visible)
    }
  },
)
