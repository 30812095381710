import {
  Controller
} from 'stimulus'
import application from 'controllers'

application.register(
  'ui--notification',
  class extends Controller {
    static targets = ["notification"]

    connect() {
      setTimeout(() => {
        this.close()
      }, 10000);
    }

    close(evt) {
      // Remove with transition
      this.element.classList.remove('transform', 'ease-out', 'duration-300', 'translate-y-2', 'opacity-0', 'sm:translate-y-0', 'sm:translate-x-2', 'translate-y-0', 'sm:translate-x-0');
      this.element.classList.add('ease-in', 'duration-100');

      // Trigger transition
      setTimeout(() => {
        this.element.classList.add('opacity-y-0');
      }, 50);

      // Remove element after transition
      setTimeout(() => {
        this.element.remove();
      }, 200);
    }
  }
)
