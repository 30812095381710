// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "@fortawesome/fontawesome-free/js/all"
import  "stylesheets/application.scss"
import "../components"

import Rails from "@rails/ujs"
import {Turbo} from "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import { Controller } from 'stimulus'
import application from 'controllers'


window.Turbo = Turbo

FontAwesome.config.mutateApproach = 'sync'

Rails.start()
ActiveStorage.start()

import "controllers"
import "components"


window.initMap = function(...args) {
  const event = new CustomEvent("google-maps-callback", {
    bubbles: true,
    cancelable: true
  })
  event.args = args
  window.dispatchEvent(event)
}

document.addEventListener("turbo:load", function() {
  if (document.getElementById("language")) {
    document.getElementById("language").addEventListener("change", (evt) => {
      evt.target.parentElement.submit()
    })
  }
})

application.register(
  'registrations--new',
  class extends Controller {
    static targets = ["modal"]

    connect() {
      document.addEventListener("keydown", this.closeHandler.bind(this))
    }

    disconnect() {
      document.removeEventListener("keydown", this.closeHandler.bind(this))
    }

    closeHandler(event) {
      if (event.keyCode == 27) {
        this.closeModal();
      }
    }

    openModal() {
      this.modalTarget.classList.remove('hidden');
    }

    closeModal() {
      this.modalTarget.classList.add('hidden');
    }
  }
)
