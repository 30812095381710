import { Controller } from 'stimulus'
import application from 'controllers'

application.register(
  'edit-lan-r1-module',
  class extends Controller {
    static targets = ["lanDHCPParams"]
    static values = {
      url: String
    }

    toggle() {
      this.lanDHCPParamsTarget.classList.toggle("hidden", this.lanDHCPParamsTarget.checked)
    }
  },
)
