import { Controller } from "stimulus"
import application from 'controllers'

application.register(
  "upgrade-profile",
  class extends Controller {
    static targets = ["profile", "wantedOsVersion", "modalNote", "modalPing"]

    connect() {
      document.addEventListener("keydown", this.closeHandler.bind(this))
      this.firmwareselect()
    }

    firmwareselect() {
      this.wantedOsVersionTarget.textContent = this.profileTarget.selectedOptions[0].dataset.osVersion
    }

    disconnect() {
      document.removeEventListener("keydown", this.closeHandler.bind(this))
    }

    closeHandler(event) {
      if (event.keyCode == 27) {
        this.closeNoteModal();
        this.closePingModal();
      }
    }

    closeNoteModal() {
      this.modalNoteTarget.classList.add('hidden');
    }

    closePingModal() {
      this.modalPingTarget.classList.add('hidden');
    }
  }
)
