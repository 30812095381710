import {
  Controller
} from 'stimulus'
import application from 'controllers'

application.register(
  'client--devices--index',
  class extends Controller {
    static targets = [
      "devices",
      "search",
      "sort"
    ]

    connect() {
      this.searchTarget.focus()
      this.searchTarget.addEventListener('input', this.search.bind(this))

      this.sortTarget.addEventListener('click', this.sort.bind(this))
    }

    search() {
      const search = this.searchTarget.value.toLowerCase()

      this.element.querySelectorAll('.device').forEach(device => {
        const name = device.dataset.name.toLowerCase()

        if (name.includes(search)) {
          device.classList.remove('hidden')
        } else {
          device.classList.add('hidden')
        }
      })
    }

    sort() {
      const sortedDevices = Array.from(document.querySelectorAll('.device')).reverse()

      this.devicesTarget.innerHTML = null

      sortedDevices.forEach(device => {
        this.devicesTarget.appendChild(device)
      })
    }
  }
)
