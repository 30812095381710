import { Controller } from 'stimulus'
import application from 'controllers'

application.register(
  'internal-ipf-el-module',
  class extends Controller {
    static targets = ["ipfElParams", "ipfElEnabled"]

    connect() {
      this.enabled = this.ipfElEnabledTarget.checked
      this.toggleElement(this.ipfElParamsTarget, this.enabled)
    }

    toggleParams() {
      this.enabled = !this.enabled
      this.toggleElement(this.ipfElParamsTarget, this.enabled)
    }

    toggleElement(element, visible) {
      element.classList.toggle("hidden", !visible)
    }
  },
)
