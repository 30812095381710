import {
  Controller
} from 'stimulus'
import application from 'controllers'

application.register(
  'client--ui--flash',
  class extends Controller {
    static targets = ['flash']

    connect() {
      setTimeout(() => {
        this.flashTarget.remove()
      }, 3000)
    }
  }
)
