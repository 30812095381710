import { Controller } from 'stimulus'
import application from 'controllers'

application.register(
  'internal-devices-edit',
  class extends Controller {
    static values = {
      deviceid: String,
      servicetag: String
    }

    initialize() {
      this.connectionEl = `device_${this.deviceidValue}_connection_state`
      this.dhcp_wan_ip = {
        id: `device_${this.deviceidValue}_dhcp_wan_ip_address`,
        url: `/internal/devices/${this.servicetagValue}/broadcast_dhcp_wan_ip_address`
      }
      this.dhcp_wlan_ip = {
        id: `device_${this.deviceidValue}_dhcp_wlan_ip_address`,
        url: `/internal/devices/${this.servicetagValue}/broadcast_dhcp_wlan_ip_address`
      }
      this.wifi_status = {
        id: `device_${this.deviceidValue}_wifi_status`,
        url: `/internal/devices/${this.servicetagValue}/broadcast_wifi_status`
      }
    }

    connect() {
      this.loadFrames()
      document.addEventListener("turbo:before-stream-render", (e) => {
        let frame = document.getElementById(this.connectionEl)

        if (frame.complete) {
          this.loadFrames()
        } else {
          frame.loaded.then(this.loadFrames)
        }
      })
    }

    loadFrames() {
      this.reloadFrame(this.dhcp_wan_ip)
      this.reloadFrame(this.dhcp_wlan_ip)
      this.reloadFrame(this.wifi_status)
    }

    reloadFrame(obj) {
      let el = document.getElementById(obj.id)
      if (!el) { return }
      for (let element of el.querySelectorAll("a")) {
        element.classList.add('loading')
      }
      if (el.src) {
        el.reload()
      } else {
        el.setAttribute("src", obj.url)
      }
    }
  }
)
