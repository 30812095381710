import { Controller } from 'stimulus'
import application from 'controllers'

application.register(
  'workstations',
  class extends Controller {
    static targets = ["deleteBox", "deleteButton", "cancelButton"]

    enableDelete() {
      this.deleteBoxTarget.classList.remove('hidden')
      this.deleteButtonTarget.classList.add('hidden')
      this.cancelButtonTarget.classList.remove('hidden')
    }

    disableDelete() {
      window.location.reload();
    }
  },
)
