import {
  Controller
} from 'stimulus'
import application from 'controllers'

application.register(
  'client--sessions--new',
  class extends Controller {
    static targets = ["form", "submitButton", "email", "password"]

    submit(event) {
      event.preventDefault()

      const email = this.emailTarget.value
      const password = this.passwordTarget.value

      window.electronAPI.storeOvpnCredentials(email, password).then((result) => {
        if (result) {
          this.formTarget.submit()
        } else {
          alert('Error saving credentials')
        }
      })
    }
  },
)
