import { Controller } from 'stimulus'
import application from 'controllers'

application.register(
  'tags-menu',
  class extends Controller {
    static targets = ["button", "tagList"]

    toggle(event) {
      event.preventDefault()
      this.tagListTarget.classList.toggle("hidden");
    }
  }
)
