import { Controller } from 'stimulus'
import application from 'controllers'

application.register(
  'ui--ip-address-prefix',
  class extends Controller {
    static targets = ["address", "prefix", "netmask", "realAddress"]
    static values = {
      name: String
    }

    connect() {
      this.updateNetmask(this.prefixTarget.value)

      if (this.nameValue === 'lan_ifaddr') {
        this.updateDHCPRange()
      }
    }

    updatePrefix(evt) {
      this.validateInputValueRange(evt, 1, 32)
      this.updateNetmask(this.prefixTarget.value)
      this.updateRealAddress()
    }

    validateInputValueRange(evt, min, max) {
      let value = evt.target.value

      if (value < min || value > max) {
        evt.target.value = 1
      }
    }

    updateAddress() {
      this.updateRealAddress()

      if (this.nameValue === 'lan_ifaddr') {
        this.updateDHCPRange()
      }
    }

    updateNetmask(prefix) {
      let nm = this.fromPrefixToNetmask(prefix)
      this.netmaskTarget.textContent = nm
    }


    // private
    updateRealAddress() {
      this.realAddressTarget.value = `${this.addressTarget.value}/${this.prefixTarget.value}`
    }

    updateDHCPRange() {
      if (!this.addressTarget || !this.addressTarget.value) {
        return;
      }

      const newOctets = this.addressTarget.value.split('.').slice(0, 3).join('.');
      const fieldsToUpdate = ['lan_dhcp_start', 'lan_dhcp_limit'];

      fieldsToUpdate.forEach(field => {
        const prefixElement = document.getElementById(`${field}_prefix`);
        const inputElement = document.getElementById(`device_${field}`);

        if (!prefixElement || !inputElement) {
          return;
        }

        const lastPrefixOctet = prefixElement.textContent.split('.').pop();
        const lastOctet = inputElement.value.split('.').pop();

        prefixElement.textContent = `${newOctets}.${lastPrefixOctet}`;
        inputElement.value = `${newOctets}.${lastOctet}`;
      });
    }


    fromPrefixToNetmask(bitCount) {
      var mask = [], i, n;

      for (i = 0; i < 4; i++) {
        n = Math.min(bitCount, 8)
        mask.push(256 - Math.pow(2, 8 - n))
        bitCount -= n
      }

      if (isNaN(mask[0])) {
        return '_._._._'
      } else {
        return mask.join('.')
      }
    }
  }
)
