import { Controller } from 'stimulus'
import application from 'controllers'

application.register(
  'devices-login',
  class extends Controller {
    static values = {
      url: String,
      id: String,
    }

    initialize() {
      setTimeout(() => {
        this.ping()
      }, 3000)
    }

    retry(event) {
      event.preventDefault()
      this.hideElement(event.target)
      this.hideElement(document.getElementById("alert-box"))
      this.ping()
    }

    closeAlert(event) {
      event.preventDefault()
      this.hideElement(document.getElementById("alert-box"))
    }

    ping() {
      const TIMEOUT = 5000; // Timeout after 5 seconds

      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => reject(new Error('Request timed out')), TIMEOUT);
      });

      const fetchPromise = fetch(this.urlValue + '/api/ping', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
        }
      });

      Promise.race([fetchPromise, timeoutPromise])
        .then(response => response.json())
        .then(data => {
          if (data.message == 'pong') {
            window.location.href = this.urlValue
          } else {
            this.showElement(document.getElementById("retry"))
            this.showElement(document.getElementById("alert-box"))
          }
        })
        .catch(error => {
          console.error('Error:', error);
          this.showElement(document.getElementById("retry"))
          this.showElement(document.getElementById("alert-box"))
        });
    }

    hideElement(element) {
      element.classList.add("hidden")
      element.classList.remove("inline-block", "flex")
    }

    showElement(element) {
      element.classList.remove("hidden")
      if (element.id === "retry") {
        element.classList.add("inline-block")
      } else if (element.id === "alert-box") {
        element.classList.add("flex")
      }
    }
  }
)
