import { Controller } from 'stimulus'
import application from 'controllers'

application.register(
  'edit-wan-r1-module',
  class extends Controller {
    static targets = ["wanParams", "dhcpIpParams"]
    static values = {
      url: String
    }

    toggle() {
      this.wanParamsTarget.classList.toggle("hidden", this.wanParamsTarget.checked)
      this.dhcpIpParamsTarget.classList.toggle("hidden", this.dhcpIpParamsTarget.checked)
    }
  },
)
